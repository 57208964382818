import { type VariantProps, cva } from "class-variance-authority";

export { default as Button } from "./Button.vue";

export const buttonVariants = cva(
  "inline-flex items-center justify-center gap-1 whitespace-nowrap rounded-full text font-bold ring-offset-background transition-all opacity-100 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring cursor-pointer focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      size: {
        default: "min-h-11 px-6",
        sm: "min-h-9 px-4 text-sm",
        lg: "min-h-14 px-8",
        icon: "min-h-10 w-10",
      },
      variant: {
        primary: "bg-primary text-primary-foreground hover:bg-primary-dark",
        "primary-outlined":
          "bg-transparant text-primary outline outline-1 hover:bg-primary-50",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-red-600",
        "destructive-outlined":
          "bg-transparent text-destructive border border-destructive hover:border-destructive-dark hover:text-destructive-dark",
        secondary:
          "bg-mono-black text-primary-foreground hover:bg-mono-dark-grey",
        "secondary-outlined":
          "border border-mono-dark-silver bg-transparent hover:border-mono-grey hover:text-accent-foreground",
        ghost:
          "hover:bg-mono-off-white hover:text-accent-foreground rounded-lg px-3 py-2",
        link: "text-black underline-offset-4 hover:underline rounded-sm h-fit p-0",
      },
      fullwidth: {
        true: "w-full",
      },
      loading: {
        true: "pointer-events-none",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
      fullwidth: false,
      loading: false,
    },
  },
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
